import React, { useState } from 'react';
import axios from 'axios';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const subscribe = async () => {
    try {
      if (!email) {
        setMessage('Please enter your email address.');
        return;
      }
      const response = await axios.post('https://us19.api.mailchimp.com/3.0/lists/81b1d44915/members/', {
        email_address: email,
        status: 'subscribed'
      });
      if (response.status === 200) {
        setMessage('Successfully subscribed!');
      } else {
        setMessage('Subscription failed. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className='flex-column center newsletter-container'>
      <div className="flex-column center gap-10">
        <div className="section-text">Subscribe to our newsletter</div>
        <div className="">Get updates from us.</div>
      </div>
      <div className="newsletter-input center gap-20">
        <div className="">
          <input
            type="text"
            placeholder='Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="button brown" onClick={subscribe}>Subscribe</div>
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default NewsLetter;